import {z} from "zod";

import vars from "@/config/env";

import {allEnvironmentNames} from "./environment-name";

/* eslint-disable @typescript-eslint/naming-convention */

const environmentVariablesSchema = z.object({
  AMPLITUDE_API_KEY: z.string().min(1, {message: "Required"}),
  AMPLITUDE_API_URL: z.string().optional(),
  APP_ENV: z.enum(allEnvironmentNames),
  ASSET_MANAGER_APP_URL: z.string().min(1, {message: "Required"}),
  AUTH0_AUDIENCE: z.string().min(1, {message: "Required"}),
  AUTH0_CLIENT_ID: z.string().min(1, {message: "Required"}),
  AUTH0_DOMAIN: z.string().min(1, {message: "Required"}),
  BASE_PATH: z.string().optional(),
  BUY_BOX_API_URL: z.string().min(1, {message: "Required"}),
  DATADOG_APPLICATION_ID: z.string().min(1, {message: "Required"}),
  DATADOG_CLIENT_TOKEN: z.string().min(1, {message: "Required"}),
  DEAL_ROOM_ADMIN_APP_URL: z.string().min(1, {message: "Required"}),
  GOOGLE_MAPS_API_KEY: z.string().min(1, {message: "Required"}),
  KEYBRAIN_APP_URL: z.string().min(1, {message: "Required"}),
  KEYCOMPS_APP_URL: z.string().min(1, {message: "Required"}),
  KEYDOCS_APP_URL: z.string().min(1, {message: "Required"}),
  ORGANIZATIONS_API_URL: z.string().min(1, {message: "Required"}),
  PROPERTY_ANALYZER_API_URL: z.string().min(1, {message: "Required"}),
  PROPERTY_SAGE_APP_URL: z.string().min(1, {message: "Required"}),
  PROPERTY_SEARCH_API_URL: z.string().min(1, {message: "Required"}),
  SPLIT_IO_BROWSER_API_KEY: z.string().min(1, {message: "Required"}),
  USERBACK_ACCESS_TOKEN: z.string().min(1, {message: "Required"})
});

type EnvironmentVariables = z.infer<typeof environmentVariablesSchema>;

const environmentVariables = environmentVariablesSchema.parse({
  AMPLITUDE_API_KEY: vars.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  AMPLITUDE_API_URL: vars.NEXT_PUBLIC_AMPLITUDE_API_URL,
  APP_ENV: vars.NEXT_PUBLIC_APP_ENV,
  ASSET_MANAGER_APP_URL: vars.NEXT_PUBLIC_ASSET_MANAGER_APP_URL,
  AUTH0_AUDIENCE: vars.NEXT_PUBLIC_AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: vars.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: vars.NEXT_PUBLIC_AUTH0_DOMAIN,
  BASE_PATH: vars.NEXT_PUBLIC_BASE_PATH,
  BUY_BOX_API_URL: vars.NEXT_PUBLIC_BUY_BOX_API_URL,
  DATADOG_APPLICATION_ID: vars.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: vars.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  DEAL_ROOM_ADMIN_APP_URL: vars.NEXT_PUBLIC_DEAL_ROOM_ADMIN_APP_URL,
  GOOGLE_MAPS_API_KEY: vars.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  KEYBRAIN_APP_URL: vars.NEXT_PUBLIC_KEYBRAIN_APP_URL,
  KEYCOMPS_APP_URL: vars.NEXT_PUBLIC_KEYCOMPS_APP_URL,
  KEYDOCS_APP_URL: vars.NEXT_PUBLIC_KEYDOCS_APP_URL,
  ORGANIZATIONS_API_URL: vars.NEXT_PUBLIC_ORGANIZATIONS_API_URL,
  PROPERTY_ANALYZER_API_URL: vars.NEXT_PUBLIC_PROPERTY_ANALYZER_API_URL,
  PROPERTY_SAGE_APP_URL: vars.NEXT_PUBLIC_PROPERTY_SAGE_APP_URL,
  PROPERTY_SEARCH_API_URL: vars.NEXT_PUBLIC_PROPERTY_SEARCH_API_URL,
  SPLIT_IO_BROWSER_API_KEY: vars.NEXT_PUBLIC_SPLIT_IO_BROWSER_API_KEY,
  USERBACK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_USERBACK_ACCESS_TOKEN
});

/* eslint-enable */

export default environmentVariables;

export type {EnvironmentVariables};
